import { render, staticRenderFns } from "./tableBarChart.vue?vue&type=template&id=19b4a644&scoped=true&"
import script from "./tableBarChart.vue?vue&type=script&lang=js&"
export * from "./tableBarChart.vue?vue&type=script&lang=js&"
import style0 from "./tableBarChart.vue?vue&type=style&index=0&id=19b4a644&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19b4a644",
  null
  
)

export default component.exports